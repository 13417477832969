.rc-tree-node-content-wrapper {
  height: auto !important;
}

.rc-tree-treenode {
  line-height: 32px !important;
}

.rc-tree-title {
  padding-left: 5px;
  width: 600px !important;
}

.rc-tree-iconEle {
  width: 36px !important;
}

.rc-tree-switcher {
  visibility: hidden;
}

.YJ-FolderTreeNode-Expand {
  visibility: visible;
}

.yjFolderStructureOverlayActions {
  background: white;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.25);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 0.2em 0.5em;
}

.yjFolderStructureOverlayActions p {
  color: #007599;
  font-size: 16px;
  padding-right: 5px;
  margin-left: 5px;
  margin-bottom: 0;
}

.yjFolderStructureOverlayActions p:last-child {
  padding-right: 0;
}

/* .YJ-folder-tree-node, .rc-tree .rc-tree-treenode {
  border-bottom: 1px solid #eee;
} */

.yjFolderStructureTree .anticon-folder {
  margin: 4px 0 0 3px;
}

.lbl-years {
  padding-left: 10px;
}

.yj-tree-node-error {
  color: #e3001c;
  font-size: 10.76923077px;
  ;
  line-height: 18px;
  margin-bottom: 10px;
}