/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* Source : https://gist.github.com/jayj/4012969 */
/* Font Variables */
/* Switch font family */
/* Extended class */
.yj-font-style,
.yjLblStatus .ant-card-meta-description {
  font-style: normal;
}
/* Font Sizes */
/* Color Variables */
/* Common Colors */
/* Form Label, Buttons, View Button, Tooltip */
/* Error, Warning, Success Messages */
/* Side Menu */
/* Master Layout */
/* Header Component */
/* Breadcrumb Component */
/* Title Component */
/* Main content wrapper */
/* Ant Table - Main Grid */
/* File area action panel */
/* Wizard Stepper */
/* Collapse - Accordian (Common / Primary) */
/* Collapse - Sub Accordian (Secondary) */
/* Tab Navigation (Common / Primary) */
/* Tab Navigation (Secondary) */
/* Common Grid */
/* History Grid */
/* Secondary Grid */
/* Common Modal */
/* Pagination */
/* File finder section - file area */
/* Portal file area */
/* FF Management - edit , License Management - edit */
/* Content Wrapper */
/* Drag and Drop Container */
/* Upload Progress bar */
/* Ant Drawer */
/* External Window */
/* Checkbox styles */
/* Global Variables */
/* Media queries breakpoint */
h1 {
  font-size: 30px;
}
h2 {
  font-size: 25px;
}
h3 {
  font-size: 22px;
}
h4 {
  font-size: 20px;
}
h5 {
  font-size: 18px;
}
h6 {
  font-size: 16px;
}
.yjMasterLayoutWrapper {
  background: #f0f2f5;
}
.yjMasterLayoutWrapper .ant-form-item-label label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}
.yjMasterLayoutWrapper .ant-form-item-label label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  color: #ff4d4f;
  content: '*';
  display: inline-block;
  font-size: 14px;
  line-height: 1;
  margin-left: 4px;
  margin-top: 4px;
}
.yjMainContainer {
  background-color: #ffffff;
}
.yjMainContainer .ant-table-wrapper {
  position: relative;
  z-index: 1;
}
.yjMainLayout {
  background: #f0f2f5;
}
.yjMainLayout .ant-layout-has-sider .ant-layout {
  margin-left: 70px;
}
.ant-table-cell-fix-right {
  background: #fff;
  z-index: 1;
}
.ant-tree {
  font-size: 12px;
}
.ant-tree .ant-tree-treenode {
  width: 100%;
}
.ant-tree .ant-tree-treenode .ant-tree-node-content-wrapper {
  border-bottom: 1px dotted #999999;
  width: 100%;
}
.ant-tree .ant-tree-treenode.ant-tree-treenode-disabled .ant-tree-node-content-wrapper {
  color: rgba(51, 51, 51, 0.8);
}
.yjAccordianCheckboxList .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: #ffffff;
  border-color: #24303b;
  color: #24303b;
}
.yjAccordianCheckboxList .ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  border-color: #24303b;
}
.yjAccordianCheckboxList .ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
  background-color: rgba(255, 255, 255, 0.4);
  border-color: rgba(36, 48, 59, 0.2);
}
.yjAccordianCheckboxList .ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
  border-color: rgba(231, 233, 236, 0.9);
}
.ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ffffff;
  border-color: #24303b;
  color: #24303b;
}
.ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #24303b;
}
.ant-checkbox-wrapper .ant-checkbox-disabled .ant-checkbox-inner {
  background-color: rgba(255, 255, 255, 0.4);
  border-color: rgba(36, 48, 59, 0.2);
}
.ant-checkbox-wrapper .ant-checkbox-disabled .ant-checkbox-inner::after {
  border-color: rgba(231, 233, 236, 0.9);
}
.yjFileFinderTree {
  border-radius: 0;
}
.yjFileFinderTree .ant-tree {
  background: transparent;
}
.yjFileFinderTree .ant-tree .ant-tree-list .ant-tree-treenode {
  padding: 0 0 2px 0;
}
.yjFileFinderTree .ant-tree .ant-tree-list .ant-tree-treenode .ant-tree-node-content-wrapper {
  line-height: 22px;
  margin: 0 15px;
  min-height: 30px;
  padding: 5px 0;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: -ms-inline-flex;
  display: inline-flex;
}
.yjFileFinderTree .ant-tree .ant-tree-list .ant-tree-treenode .ant-tree-node-content-wrapper .ant-tree-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(70% - 30px);
}
.yjFileFinderTree .ant-tree .ant-tree-list .ant-tree-treenode:hover .ant-tree-title {
  color: #0e678e;
}
.yjFileFinderTree .ant-tree .ant-tree-list .ant-tree-treenode:hover::before {
  background: rgba(14, 103, 142, 0.2);
}
.yjFileFinderTree .ant-tree .ant-tree-list .ant-tree-treenode.ant-tree-treenode-selected .ant-tree-title {
  color: #ffffff;
  font-family: 'Roboto Regular SemiBold';
}
.yjFileFinderTree .ant-tree .ant-tree-list .ant-tree-treenode.ant-tree-treenode-selected .ant-tree-iconEle .anticon {
  color: #ffffff;
}
.yjFileFinderTree .ant-tree .ant-tree-list .ant-tree-treenode.ant-tree-treenode-selected::before {
  background: rgba(14, 103, 142, 0.5);
}
.yjFileFinderTree .ant-tree .ant-tree-list .ant-tree-switcher {
  width: 0.5em;
}
.yjFileFinderTree .ant-tree .ant-tree-list .ant-tree-switcher .anticon.ant-tree-switcher-icon {
  display: none;
}
.yjFileFinderTree .ant-tree .ant-tree-list .ant-tree-iconEle .anticon {
  color: #0e678e;
  margin-top: 3px;
}
.yjFileFinderTree .ant-tree .ant-tree-list .ant-tree-title {
  color: rgba(0, 0, 0, 0.65);
  font-size: 12.17391304px;
  margin-left: 0.5em;
}
.yjFileFinderTree .ant-tree .ant-tree-treenode::before {
  bottom: 0;
}
.ant-form label {
  font-size: 12px;
}
.ant-menu-submenu-popup .ant-menu-item-selected,
.ant-menu-submenu-popup .ant-menu-item-active {
  background: #24303b;
}
.ant-menu.ant-menu-dark {
  background: inherit;
}
.ant-menu.ant-menu-dark .ant-menu-sub {
  background: #0e678e;
}
.ant-menu.ant-menu-dark .ant-menu-item:hover {
  background-color: rgba(36, 48, 59, 0.8);
}
.ant-menu-vertical .ant-menu-item {
  margin: 0 auto;
}
.ant-menu-vertical .ant-menu-item:not (:last-child) {
  margin-bottom: 0;
}
.yjAccMenuItemList li label span {
  font-size: 11.66666667px;
  opacity: 1;
}
.ant-menu.ant-menu-inline-collapsed {
  margin: 0 -5px;
  width: 75px;
}
.ant-menu li {
  text-transform: uppercase;
}
.ant-menu li .anticon {
  font-size: 1.3em;
}
.ant-menu li span {
  font-size: 0.92em;
}
.ant-menu li ul li {
  font-size: 0.98em;
}
.ant-menu .ant-menu-submenu .ant-menu-submenu-title .anticon {
  font-size: 1.3em;
}
.ant-menu .ant-menu-submenu .ant-menu-item-selected,
.ant-menu .ant-menu-submenu .ant-menu-item-active {
  background-color: #48bbed;
}
/* Common styles for inputs with label - Added to keep the consistency of the inputs */
.ant-form-item .ant-form-item-label label {
  color: #0e678e;
  font-size: 12.22707424px;
  height: 22px;
  text-transform: uppercase;
  font-family: 'Roboto Regular SemiBold';
}
.ant-form-item-with-help {
  margin-bottom: 0;
}
.ant-tag-blue .ant-form-item {
  margin-bottom: 0;
}
.ant-tag-blue .ant-form-item .ant-form-item-control-input-content .ant-input {
  padding: 4px;
}
.ant-btn-primary {
  background: #24303b;
  font-size: 12px;
  margin-left: 5px;
  text-transform: uppercase;
}
.ant-btn-default {
  background: #ffffff;
  font-size: 12px;
  margin-left: 5px;
  text-transform: uppercase;
}
.ant-btn[disabled] {
  background: transparent;
}
.ant-btn.ant-dropdown-trigger {
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 0.2em;
  color: #ffffff;
  font-size: 12px;
  margin-left: 5px;
  text-transform: uppercase;
}
.ant-form-item-control .ant-form-item-explain {
  color: #C8102E;
  line-height: 2.5;
  min-height: 30px;
}
.ant-form-item-control .ant-form-item-explain div {
  font-size: 10.76923077px;
}
.yjActionIconWrapper {
  align-items: center;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  justify-content: space-between;
}
.yjActionIconWrapper button {
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: #24303B;
  margin-left: 0;
}
.yjActionIconWrapper button:hover {
  background-color: transparent;
  color: #4b647a;
}
.yjActionIconWrapper button:focus {
  background-color: transparent;
}
.yjActionIconWrapper button:active {
  background-color: transparent;
}
.yjActionIconWrapper button:disabled {
  background-color: transparent;
}
.yjActionIconWrapper .yjPortalGridDeleteAction {
  color: #C8102E;
}
.ant-modal .ant-modal-content {
  background: #ffffff;
}
.ant-modal .ant-modal-content .ant-modal-close-x .ant-modal-close-icon {
  color: #ffffff;
}
.ant-modal .ant-modal-content .ant-modal-header {
  background: #24303b;
  border-bottom: 1px solid #f6f6f6;
}
.ant-modal .ant-modal-content .ant-modal-header .ant-modal-title {
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
  width: 98%;
  font-family: 'Roboto Regular SemiBold';
}
.ant-modal .ant-modal-content .ant-modal-footer button {
  font-size: 12px;
  font-family: 'Roboto Regular SemiBold';
}
.ant-modal .ant-modal-content .ant-modal-footer button:last-child {
  background-color: #24303b;
  border-color: #24303b;
  color: #ffffff;
  margin-right: 0;
}
.ant-modal .ant-modal-content .ant-modal-footer .ant-btn-primary[disabled] {
  background: #bcbcbc;
  border-color: #aeaeae;
  color: rgba(0, 0, 0, 0.65);
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
}
.ant-table tbody > tr.ant-table-row:hover > td {
  background: #e2ecf3;
}
.ant-table tbody > tr.ant-table-row:hover > td.yjActionColumn {
  background: #e2ecf3;
}
.ant-table tbody tr.table-row-dark {
  background-color: #f0f5f9;
}
.ant-table tbody tr.table-row-dark td {
  background-color: #f0f5f9;
}
.ant-table tbody tr.table-row-dark td.yjActionColumn {
  background-color: #f0f5f9;
}
.ant-table tbody tr td.ant-table-cell {
  font-size: 13px;
  padding: 2px 16px;
}
.ant-table .ant-table-tbody tr.ant-table-row-selected td {
  background: inherit;
}
.ant-select {
  width: 100%;
}
.ant-select-item.ant-select-item-option {
  font-size: 12.72727273px;
}
.ant-select-item.ant-select-item-option .ant-select-item-option-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 200px;
}
.yjChannelSelector {
  border: 1px solid #24303b;
  border-radius: 0.2em;
  margin: 0 0.16em;
  width: 20vw;
}
.ant-pagination-options .ant-select .ant-select-selector {
  font-size: 12px;
}
.ant-picker {
  cursor: default;
}
.ant-picker .ant-picker-input > input {
  font-size: 12.72727273px;
}
.ant-picker .ant-picker-suffix {
  pointer-events: auto;
}
.ant-picker .ant-picker-suffix .anticon svg {
  cursor: default;
}
.yjProgressBar .ant-progress-outer .ant-progress-inner {
  background-color: #cbcfd5;
  height: 5px;
}
.yjProgressBar .ant-progress-outer .ant-progress-inner .ant-progress-bg {
  background-color: #78bf59;
}
.ant-popover .ant-list .ant-list-item {
  cursor: pointer;
}
.yjStepper {
  background-color: #ffffff;
  border-radius: 3px;
  padding: 1em;
}
.yjStepper .ant-steps .ant-steps-item-active .ant-steps-item-icon {
  background-color: #C8102E;
  border-color: #C8102E;
}
.yjStepper .ant-steps .ant-steps-item-wait .ant-steps-item-icon {
  background-color: #ffffff;
  border-color: #cccccc;
}
.yjStepper .ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #C8102E;
  border-color: #C8102E;
}
.yjStepper .ant-steps .ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon .ant-steps-finish-icon svg {
  color: #ffffff;
}
.yjStepper .ant-steps .ant-steps-item-finish .ant-steps-item-content .ant-steps-item-title::after {
  background-color: #78bf59;
}
.yjDrawerPanel .ant-drawer-content-wrapper {
  width: 450px;
}
.yjDrawerPanel .ant-drawer-content-wrapper .ant-drawer-header {
  background: #24303b;
}
.yjDrawerPanel .ant-drawer-content-wrapper .ant-drawer-header .ant-drawer-title {
  color: #ffffff;
  font-size: 15.2px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
  width: 90%;
}
.yjDrawerPanel .ant-drawer-content-wrapper .ant-drawer-header .ant-drawer-title span {
  margin-right: 10px;
}
.yjDrawerPanel .ant-drawer-content-wrapper .ant-drawer-header .ant-drawer-close {
  color: #ffffff;
  cursor: pointer;
}
.yjDrawerPanel .ant-drawer-content-wrapper .ant-drawer-body {
  background: #ffffff;
}
.yjCommonAccordian {
  border: 0;
  margin-bottom: 16px;
}
.yjCommonAccordian .ant-collapse {
  border: none;
}
.yjCommonAccordian .ant-collapse .ant-collapse-item {
  box-shadow: none;
  margin-bottom: 10px;
}
.yjCommonAccordian .ant-collapse .ant-collapse-item .ant-collapse-header {
  background: #24303b;
  border-radius: 0;
  color: #ffffff;
  font-size: 11.66666667px;
  text-transform: uppercase;
  font-family: 'Roboto Regular SemiBold';
}
.yjCommonAccordian .ant-collapse .ant-collapse-item .ant-collapse-header .ant-collapse-arrow svg {
  color: #ffffff;
}
.yjCommonAccordian .ant-collapse .ant-collapse-item .ant-collapse-header .yjAccordianLabel {
  display: flex;
  overflow: hidden;
  padding-top: 3px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 90%;
}
.yjCommonAccordian .ant-collapse .ant-collapse-item .ant-collapse-header .ant-checkbox-disabled + span {
  color: #ffffff;
}
.yjCommonAccordian .ant-collapse .ant-collapse-item .ant-collapse-extra {
  padding-top: 3px;
}
.yjCommonAccordian .ant-collapse .ant-collapse-item.ant-collapse-no-arrow .ant-collapse-header {
  cursor: default;
  padding-left: 16px;
}
.yjCommonAccordian .ant-collapse .ant-collapse-item .ant-collapse-content {
  border: 1px solid #e7e9ec;
  border-bottom: 0;
  max-height: 352px;
  overflow-y: auto;
}
.yjCommonAccordian .ant-collapse .ant-collapse-item .ant-collapse-content .ant-collapse-content-box {
  font-size: 11.66666667px;
}
.yjCommonAccordian .ant-collapse .ant-collapse-item .ant-collapse-content .ant-collapse-content-box .ant-list-empty-text {
  color: #24303b;
}
.yjAccordianCheckbox {
  float: left;
  padding-right: 10px;
}
.ant-collapse-content .ant-collapse-content-box {
  background: #ffffff;
}
.ant-collapse-content .ant-collapse-content-box .ant-collapse {
  background: transparent;
  border: none;
}
.ant-collapse-content .ant-collapse-content-box .ant-collapse.yjSecondaryCollapsePanel .site-checkbox-all-wrapper ul li {
  padding: 5px;
}
.ant-collapse-content .ant-collapse-content-box .ant-collapse.yjSecondaryCollapsePanel .site-checkbox-all-wrapper ul li label {
  padding-left: 25px;
  text-indent: -25px;
}
.ant-collapse-content .ant-collapse-content-box .ant-collapse.yjSecondaryCollapsePanel .site-checkbox-all-wrapper ul ul .ant-checkbox-wrapper {
  display: block;
  padding-left: 15px;
  text-indent: -22px;
}
.ant-collapse-content .ant-collapse-content-box .ant-collapse .ant-collapse-item {
  border-bottom: none;
  margin-bottom: 0;
}
.ant-collapse-content .ant-collapse-content-box .ant-collapse .ant-collapse-item .ant-collapse-header {
  background: #ffffff;
  border: none;
  border-bottom: 1px solid #e7e9ec;
  color: #0e678e;
  font-family: 'Roboto Regular SemiBold';
}
.ant-collapse-content .ant-collapse-content-box .ant-collapse .ant-collapse-item .ant-collapse-header span {
  border: none;
}
.ant-collapse-content .ant-collapse-content-box .ant-collapse .ant-collapse-item .ant-collapse-header .ant-collapse-arrow svg {
  color: #0e678e;
}
.ant-collapse-content .ant-collapse-content-box .ant-collapse .ant-collapse-item .ant-collapse-content {
  border: none;
  text-transform: capitalize;
}
.ant-collapse-content .ant-collapse-content-box .ant-collapse .ant-collapse-item .ant-collapse-content .ant-collapse-content-box {
  padding: 10px 5px 5px;
}
.yjSecondaryAccordian {
  border: 0;
  margin-bottom: 16px;
}
.yjSecondaryAccordian .ant-collapse {
  border: none;
}
.yjSecondaryAccordian .ant-collapse .ant-collapse-item {
  box-shadow: none;
  margin-bottom: 10px;
}
.yjSecondaryAccordian .ant-collapse .ant-collapse-item .ant-collapse-header {
  background: #24303b;
  border-radius: 0;
  color: #ffffff;
  font-size: 11.66666667px;
  text-transform: uppercase;
  font-family: 'Roboto Regular SemiBold';
}
.yjSecondaryAccordian .ant-collapse .ant-collapse-item .ant-collapse-header .ant-collapse-arrow svg {
  color: #ffffff;
}
.yjSecondaryAccordian .ant-collapse .ant-collapse-item .ant-collapse-header .yjAccordianLabel {
  display: flex;
  overflow: hidden;
  padding-top: 3px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 90%;
}
.yjSecondaryAccordian .ant-collapse .ant-collapse-item .ant-collapse-header .ant-checkbox-disabled + span {
  color: #ffffff;
}
.yjSecondaryAccordian .ant-collapse .ant-collapse-item .ant-collapse-extra {
  padding-top: 3px;
}
.yjSecondaryAccordian .ant-collapse .ant-collapse-item.ant-collapse-no-arrow .ant-collapse-header {
  cursor: default;
  padding-left: 16px;
}
.yjSecondaryAccordian .ant-collapse .ant-collapse-item .ant-collapse-content {
  border: 1px solid #e7e9ec;
  border-bottom: 0;
  overflow-y: auto;
}
.yjSecondaryAccordian .ant-collapse .ant-collapse-item .ant-collapse-content .ant-collapse-content-box {
  font-size: 11.66666667px;
}
.ant-alert-info {
  background-color: #b8daff;
  border-color: #b8daff;
  color: #004085;
  margin-bottom: 5px;
  padding-left: 50px;
}
.ant-alert-success {
  background-color: #d4edda;
  border-color: #c3e6cb;
  color: #155724;
  margin-bottom: 5px;
  padding-left: 50px;
}
.ant-alert-warning {
  background-color: #fff3cd;
  border-color: #ffeeba;
  color: #856404;
  margin-bottom: 5px;
  padding-left: 50px;
}
.ant-alert-error {
  background-color: #f8d7da;
  border-color: #f5c6cb;
  color: #721c24;
  margin-bottom: 5px;
  padding-left: 50px;
}
.ant-upload-list-item .ant-upload-list-item-info {
  text-align: left;
}
.ant-tabs-card .ant-tabs-bar {
  border-bottom: 1px solid #e7e9ec;
}
.ant-tabs-card .ant-tabs-bar .ant-tabs-tab {
  background: transparent;
  text-transform: uppercase;
  transition: all 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs-card .ant-tabs-bar .ant-tabs-tab:hover {
  color: #24303b;
}
.ant-tabs-card .ant-tabs-bar .ant-tabs-tab-active {
  background: transparent;
  border-bottom: 3px solid #0e678e;
  border-color: transparent;
  color: #0e678e;
}
.ant-table-wrapper .yjGridPagination {
  float: right;
  padding: 1em 1em 0;
}
.ant-table-wrapper .yjGridPagination .ant-pagination-item-active {
  background: #24303b;
  border-color: #24303b;
}
.ant-table-wrapper .yjGridPagination .ant-pagination-item-active a {
  color: #ffffff;
}
.yjGridTextWrap {
  margin: 0;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 98%;
}
.yjGridTextCenter {
  margin: 0;
  padding: 0;
  text-align: center;
}
.yjGridTextCenterDashboard {
  margin: 0;
  padding: 0;
  text-align: center;
}
.yjColorTag {
  text-transform: uppercase;
  width: 100px;
  font-family: 'Roboto Regular SemiBold';
}
.table-row-light {
  background-color: #ffffff;
}
.yjTableContainer {
  /* Grid sorting background transparent issue fix */
}
.yjTableContainer .ant-table-content table {
  table-layout: fixed;
}
.yjTableContainer .ant-table-thead .ant-checkbox-wrapper {
  margin-left: 10px;
}
.yjTableContainer .ant-table-thead .yjGridTextFilterSelect .ant-select-selector {
  border-color: #99ccdc;
}
.yjTableContainer .ant-table-thead .ant-picker-range {
  border-color: #99ccdc;
}
.yjTableContainer .ant-table-thead .ant-table-column-sorter-full {
  margin-top: -3.2em;
}
.yjTableContainer .ant-table-thead tr th {
  background: #E8F0F8;
  box-shadow: none;
}
.yjTableContainer .ant-table-thead tr th:hover {
  background: #d8ecf2;
  transition: none;
}
.yjTableContainer .ant-table-thead tr th > div {
  width: 100%;
}
.yjTableContainer .ant-table-thead tr th > div > span:first-child {
  width: 100%;
}
.yjTableContainer .ant-table-thead tr th .ant-table-column-sorters {
  padding: 10px;
}
.yjTableContainer .ant-table-thead tr .yjActionColumn {
  padding-top: 0;
  text-align: center;
}
.yjTableContainer .ant-table-thead tr .yjActionColumn span {
  left: 20px;
  position: absolute;
  top: 17px;
}
.yjTableContainer .ant-table-thead .table-row-dark {
  background-color: #f6fdff;
}
.yjTableContainer .ant-table-expanded-row-fixed {
  margin: 0;
  padding: 0;
}
.yjMultiSelectOptionSelect .ant-select-dropdown-empty {
  padding-bottom: 10px;
  text-align: center;
}
.yjMultiSelectOptionSelect .ant-select-selection-item {
  background-color: rgba(36, 48, 59, 0.2);
  border: 1px dashed #24303b;
  font-size: 12px;
  margin-right: 3px;
  padding-left: 3px;
}
.yjMultiSelectOptionSelect .ant-select-selector {
  padding-right: 22px;
}
.yjMultiSelectOptionSelect .ant-select-selection-search {
  max-width: 90%;
}
.yjMultiSelectOptionSelect .ant-select-selection-search .ant-select-selection-search-input {
  width: 90%;
}
.ant-select-selection-overflow-item {
  max-width: 90%;
}
.yjInfinateScrollMultiselector .ant-select-selection-item {
  background: none;
  background-color: none;
  border: none;
  color: #bfbfbf;
}
.yjInfinateScrollMultiselector .ant-select-dropdown {
  z-index: 1;
}
.ant-table-column-has-sorters .ant-table-column-sorter {
  margin-left: -11px;
}
.ant-table-column-sorter-inner {
  color: #9bb7c0;
}
.ant-table-header .ant-table-cell .ant-select-selector {
  border-color: #99ccdc;
}
.ant-table-header .ant-table-cell .ant-picker {
  border-color: #99ccdc;
}
.yjViewButton {
  background-color: #134A82;
  border-color: #134A82;
  color: #ffffff;
  font-size: 0.75em;
  text-transform: uppercase;
  font-family: 'Roboto Regular SemiBold';
}
.yjViewButton:hover,
.yjViewButton:active,
.yjViewButton:focus {
  background: #134A82;
  color: #ffffff;
}
.yjModuleSubHeading {
  color: #0e678e;
  font-size: 15.2px;
  margin-bottom: 1em;
  margin-top: 20px;
  text-transform: uppercase;
  font-family: 'Roboto Regular Bold';
}
.ant-modal-body {
  max-height: calc(80vh);
  overflow-y: scroll;
}
::-webkit-scrollbar {
  height: 0.4em;
  width: 0.4em;
}
::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 8px;
}
::-webkit-scrollbar-thumb {
  background-color: #808080;
  border-radius: 8px;
  font-size: 12px;
  height: 8px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #374a5b;
  border-color: #4b647a;
  color: #ffffff;
}
::-webkit-scrollbar-thumb:focus {
  background-color: #24303b;
  border-color: #24303b;
  color: #ffffff;
}
.ant-dropdown-menu-item {
  font-size: 12px;
}
.yjMoverArrow {
  align-items: center;
  display: flex;
  justify-content: center;
}
.yjDeteleFile {
  background: #C8102E;
  border: none;
  box-shadow: none;
  height: 20px;
  margin-right: 10px;
  width: 20px;
}
.yjDeteleFile:hover {
  background: #C8102E;
  border: none;
  box-shadow: none;
}
.yjDeteleFile:focus {
  background: #C8102E;
  border: none;
  box-shadow: none;
}
.yjDeteleFile span {
  color: #ffffff;
  font-size: 14px;
  vertical-align: 3px;
}
.ant-tooltip-inner {
  background-color: #134A82;
  box-shadow: none;
  color: #ffffff;
  font-size: 0.75em;
}
.ant-tooltip-arrow-content {
  background-color: #134A82;
}
.ant-upload-list-picture {
  margin-top: 35px;
}
.ant-upload-list {
  max-height: 100px;
  overflow: scroll;
}
.yjfilterTag {
  display: inline-flex;
}
.yjfilterTag .ant-tag {
  margin-bottom: 0.5em;
}
.ant-notification-notice.yjErrorMsg {
  width: 420px;
}
.yjErrorMsg {
  background: #f5cace;
  border: 1px solid #f5cace;
}
.yjErrorMsg .ant-notification-notice-message {
  color: #C8102E;
  font-size: small;
}
.yjWarningMsg {
  background: #ffeeba;
  border: 1px solid #ffeeba;
}
.yjWarningMsg .ant-notification-notice-message {
  color: #856404;
}
.yjSuccessMsg {
  background: #c3e6cb;
  border: 1px solid #c3e6cb;
}
.yjSuccessMsg .ant-notification-notice-message {
  color: #155724;
}
.yjInfoMsg {
  background: #CBD8EB;
  border: 1px solid #CBD8EB;
}
.yjInfoMsg .ant-notification-notice-message {
  color: #0e678e;
}
.yjInfoMsg .ant-notification-notice-content svg {
  color: #0e678e;
}
.yjCommonTabs .ant-tabs-card .ant-tabs-nav {
  padding-bottom: 10px;
}
.yjCommonTabs .ant-tabs-card .ant-tabs-nav .ant-tabs-tab {
  background: #ffffff;
  border: none;
  border-right: 1px solid #f0f0f0;
  color: #333333;
  padding: 5px 10px;
}
.yjCommonTabs .ant-tabs-card .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-btn {
  font-size: 14px;
  font-weight: 'Bold';
  text-transform: uppercase;
}
.yjCommonTabs .ant-tabs-card .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  background: #ffffff;
  color: #0e678e;
  font-size: 14px;
  font-weight: 'Bold';
  text-transform: uppercase;
}
.yjSecondaryTabs .ant-tabs-top .ant-tabs-nav {
  margin: 0;
}
.yjSecondaryTabs .ant-tabs-top .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab {
  background: #f0f2f5;
  border: 1px solid #78bf59;
  border-bottom: none;
  color: #333333;
  margin: 0;
  padding: 5px 40px;
}
.yjSecondaryTabs .ant-tabs-top .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
  font-size: 14px;
  font-weight: 'Bold';
  text-transform: uppercase;
}
.yjSecondaryTabs .ant-tabs-top .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active {
  background: #78bf59;
  border: none;
  color: #ffffff;
  margin: 0;
}
.yjSecondaryTabs .ant-tabs-top .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffffff;
  font-size: 14px;
  font-weight: 'Bold';
  text-transform: uppercase;
}
.yjSecondaryTabs .ant-tabs-top .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-ink-bar {
  background: transparent;
}
.ant-upload.ant-upload-drag .ant-upload-btn {
  padding: 5px 0;
}
.ant-modal-root .yjCommonModalSmall {
  min-width: 35vw;
}
.ant-modal-root .yjCommonModalMedium {
  min-width: 60vw;
}
.ant-modal-root .yjCommonModalLarge {
  min-width: 80vw;
}
.yjExternalUrlContentForm .ant-form-item .ant-form-item-label {
  font-size: 15.2px;
  margin-bottom: 1em;
  text-transform: uppercase;
}
.yjCardWrapper {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
  height: 110px;
  margin-bottom: 25px;
  text-align: center;
}
.yjCardWrapper .ant-card-meta-description {
  color: #24303b;
  font-size: 15.96px;
  font-weight: 'Bold';
}
.yjLblStatus .ant-card-meta-description {
  background: #78bf59;
  border-radius: 3px;
  color: #ffffff;
  font-size: 14px;
  padding: 5px;
}
.yjLicenedModuleCard .ant-card-head {
  background: linear-gradient(180deg, #b8dce8 0%, #d8ecf2 100%);
  font-size: 12.6px;
  min-height: auto;
  padding: 5px 10px;
  text-transform: uppercase;
}
.yjLicenedModuleCard .ant-card-body {
  border: 1px solid #e7e9ec;
  padding: 15px;
}
.yjLicenedModuleCard .ant-card-head-title {
  padding: 0;
}
.yjLicenedModuleCard p {
  border-bottom: 1px solid #e7e9ec;
  margin-bottom: 0;
  padding: 3px 0;
}
.yjPieChartWrapper {
  margin-bottom: 0;
}
.yjPieChartWrapper .ant-collapse-item .ant-collapse-content {
  max-height: 352px;
}
.yjPendingFilesCarddWrapper {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
  height: 65px;
  margin-bottom: 15px;
  text-align: left;
  width: 50%;
}
.yjPendingFilesCarddWrapper .ant-card-meta-detail {
  display: flex;
}
.yjPendingFilesCarddWrapper .ant-card-meta-detail .ant-card-meta-title {
  border-right: 1px solid #ccc;
  text-align: center;
  width: 50%;
}
.yjPendingFilesCarddWrapper .ant-card-meta-detail .ant-card-meta-description {
  color: #0e678e;
  margin-top: 2px;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 50%;
}
.yjDashboardTableWrapper .ant-table-thead tr th {
  background-color: #ffffff;
  color: #0e678e;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}
.yjDashboardTableWrapper .ant-table-thead .yjDashboradTextCenter {
  text-align: center;
}
.yjDashboardTableWrapper .ant-table tbody tr.ant-table-row:hover td {
  background: transparent;
}
.yjDashboardTableWrapper .ant-table tbody .yjDashboradTextWrap {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 98%;
}
.yjNoWrapperButton {
  border: none;
  box-shadow: none;
  color: #0e678e;
}
.yjNoWrapperButton:hover {
  background: transparent;
}
.yjCustomTblHeader .yjTableContainer .ant-table-thead .ant-table-column-sorter-full {
  margin-top: -5px;
}
.yjChannelDescription .ant-form-item textarea.ant-input,
.yjSiteDescription .ant-form-item textarea.ant-input {
  max-height: 50px;
}
.yjContactsConfirmationPopup .ant-btn {
  font-size: 13px;
}
.yjStatusActiveInactiveConfirmationPopup {
  padding: 10px;
}
.yjStatusActiveInactiveConfirmationPopup .ant-popover-inner {
  padding: 10px;
}
.yjStatusActiveInactiveConfirmationPopup .ant-popover-inner .ant-popover-buttons {
  border-top: 1px solid #ccc;
  padding-top: 10px;
}
.yjStatusActiveInactiveConfirmationPopup .ant-popover-inner .ant-popover-buttons .ant-btn {
  padding: 0 20px;
}
.yjInfinityListClass .ant-list-item {
  border-bottom: none;
  display: block;
  padding: 0;
}
.yjDashboardWrapper .yjCommonAccordian .ant-collapse .ant-collapse-item .ant-collapse-content {
  min-height: 345px;
  overflow-x: hidden;
}
.yjManageSiteContainer .yjCommonAccordian .ant-collapse .ant-collapse-item .ant-collapse-content {
  background-color: white;
  max-height: 255px;
  min-height: 150px;
  overflow-x: hidden;
}
.yjCommonModalLarge .ant-modal-body {
  max-height: calc(74vh);
}
@media (max-width: 1366px) {
  .yjTableContainer .ant-table-thead tr th {
    padding: 16px 5px;
  }
  .yjTableContainer .ant-table-thead tr th .ant-table-column-sorters {
    padding: 0;
  }
  .yjTableContainer .ant-table-thead tr .yjActionColumn {
    padding-top: 0;
    text-align: center;
  }
  .yjTableContainer .ant-table-thead tr .yjActionColumn span {
    left: 20px;
    position: absolute;
    top: 21px;
  }
}
.yjFileAreaGridWrapper .ant-table-thead .ant-table-column-sorter-full {
  cursor: auto;
}
.yjFileAreaGridWrapper tr th.yjDraggableColumn:hover {
  cursor: move;
}
.yjGenericGridColumnTitle {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.yjDraggableColumnHoverLeft {
  border-left: 3px solid #0e678e;
}
.yjDraggableColumnHoverRight {
  border-right: 3px solid #0e678e;
}
/*================ FOR DEMO ONLY. Please remove this code after the demo ======== */
.yjTmpPad {
  padding: 0.6em;
}
/*================ FOR DEMO ONLY. Please remove this code after the demo ======== */
.yjCreateSiteContactList .ant-list-split .ant-list-item {
  border-bottom: none;
  padding: 0;
}
.yj_error_banner {
  font-size: 18px;
  line-height: 150%;
  text-align: center;
}
.yj_error_banner .yj_error_btns {
  border-radius: 5px;
  border: none;
  height: 50px;
  margin-top: 10px;
  width: 200px;
}
.yjSideMenuWrapper {
  height: calc(100vh);
  overflow-y: auto;
  padding-bottom: 125px;
  display: flex;
  flex-direction: column;
}
.yjSideMenuWrapper .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #C8102E;
}
.yjModalContainerWrapper .ant-form-item-label label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}
.yjModalContainerWrapper .ant-form-item-label label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  color: #ff4d4f;
  content: '*';
  display: inline-block;
  font-size: 14px;
  line-height: 1;
  margin-right: 4px;
  margin-top: 4px;
}
.yjSaveFilterButton {
  background: #78bf59;
  border-color: #78bf59;
  color: #ffffff;
}
.yjDropdownManageFilters {
  background: #78bf59;
  border-color: #78bf59;
  color: #ffffff;
  font-size: 14px;
  height: 30px;
  margin-left: 0;
  width: 100%;
}
.yjDropdownManageFilters:hover {
  background: #78bf59;
  border-color: #78bf59;
  opacity: 0.8;
}
.pxDashboard .ant-collapse-content {
  overflow: hidden;
  max-height: none !important;
}
/*History Page sorting icon alignment fix*/
#fileHistory .ant-table .ant-table-thead .ant-table-column-sorter-full {
  margin-top: 0;
}
.yjManageUserGroupTitle {
  background-color: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
  padding: 0 2em;
}
.yjManageUserGroupTitle h2 {
  color: #0e678e;
  margin: 0.2em 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 99%;
}
