.font-mixin(@font-family, @font-style) {
  font-family: '@{font-family} @{font-style}';
}

.flex-mixin(@align, @display, @justify) {
  .align-items(@align: @align);
  .flex-display(@display: @display);
  .justify-content(@justify: @justify);
}

/* Source : https://gist.github.com/jayj/4012969 */

// Flexbox display
// flex or inline-flex

.flex-display(@display: flex) {
  display: ~'-webkit-@{display}';
  display: ~'-ms-@{display}box'; // IE10 uses -ms-flexbox
  display: ~'-ms-@{display}'; // IE11
  display: @display;
}

// The 'flex' shorthand

// - applies to: flex items
// <positive-number>, initial, auto, or none

.flex(@columns: initial) {
  flex: @columns;
}

// Flex Flow Direction

// - applies to: flex containers
// row | row-reverse | column | column-reverse

.flex-direction(@direction: row) {
  flex-direction: @direction;
}

// Axis Alignment

// - applies to: flex containers
// flex-start | flex-end | center | space-between | space-around

.justify-content(@justify: flex-start) {
  justify-content: @justify;
}

// Cross-axis Alignment

// - applies to: flex containers
// flex-start | flex-end | center | baseline | stretch

.align-items(@align: stretch) {
  align-items: @align;
}
