/* Font Variables */

@font-path: '/styles/assets/fonts';
@font-family-icon: 'yjicon';
@font-family-primary: 'Roboto Regular';
@font-family-secondary: 'Open Sans';
@font-family-theme: 'Titillium';

/* Switch font family */

@font-primary: @font-family-primary;
@yj-base-font-family: '@{font-primary}', sans-serif;

@yj-transform: uppercase;
@yj-underline: underline;

@yjicon-assign: '\e900';
@yjicon-email: '\e901';
@yjicon-filarea: '\e902';
@yjicon-license: '\e903';
@yjicon-onboarding: '\e904';
@yjicon-organisation: '\e905';
@yjicon-properties: '\e906';

@yjff-thin: 'Thin';
@yjff-light: 'Light';
@yjff-regular: 'Regular';
@yjff-semibold: 'SemiBold';
@yjff-bold: 'Bold';
@yjff-black: 'Black';
@yjff-condensed: 'Condensed';

/* Extended class */

.yj-font-style {
  font-style: normal;
}

/* Font Sizes */

@font-size-base: .875em;
@font-size-xlg: 1.3em;
@font-size-lg: @font-size-base + 1.2em;
@font-size-sm: @font-size-base / 2.5;
@font-size-md: .75em;

/* Color Variables */

/* Common Colors */
@color-white: #ffffff;
@color-black: #333333;
@color-gray-light: #f0f2f5;
@color-gray-medium: #cccccc;
@color-gray-dark: #24303b;
@color-blue-light: #d8ecf2;
@color-blue-medium: #134A82;
@color-blue-dark: #0e678e;
@color-green: #78bf59;
@color-red: #C8102E;
@color-yellow: #ffeeba;

/* Form Label, Buttons, View Button, Tooltip */
@color-font: @color-black;
@color-font-form-label: @color-blue-dark;
@color-bg-primary-button: @color-gray-dark;
@color-font-primary-button: @color-white;
@color-bg-default-button: @color-white;
@color-font-default-button: @color-black;
@color-border-default-button: @color-gray-medium;
@color-bg-view-btn: @color-blue-medium;
@color-border-view-btn: @color-blue-medium;
@color-font-view-btn: @color-white;
@color-bg-tooltip-wrapper: @color-blue-medium;
@color-font-tooltip-wrapper: @color-white;
@color-bg-add-address-btn: @color-green;
@color-border-add-address-btn: @color-green;
@color-font-add-address-btn: @color-green;
@color-button-primary: @color-gray-dark;

/* Error, Warning, Success Messages */
@color-bg-error-message-wrapper: #f5cace;
@color-bg-warning-message-wrapper: @color-yellow;
@color-border-warning-message-wrapper: @color-yellow;
@color-font-warning-message-wrapper: #856404;
@color-bg-success-message-wrapper: #c3e6cb;
@color-border-success-message-wrapper: #c3e6cb;
@color-font-success-message-wrapper: #155724;
@color-bg-info-message-wrapper: #CBD8EB;
@color-border-info-message-wrapper: @color-blue-dark;
@color-font-info-message-wrapper: @color-blue-dark;

/* Side Menu */
@color-mainmenu-icon: @color-white;

/* Master Layout */
@color-bg-masterlayout: @color-gray-light;
@color-bg-masterlayout-content-wrapper: @color-gray-light;

/* Header Component */
@color-bg-header: #efefef;
@color-border-header: #dddddd;
@color-bg-header-menu: #efefef;
@color-font-header-menu: @color-gray-dark;
@color-font-menu-icon: @color-gray-dark;

/* Breadcrumb Component */
@color-bg-breadcrumb: @color-white;
@color-font-breadcrumb: @color-gray-dark;

/* Title Component */
@color-bg-titlewrapper: @color-white;
@color-page-title: @color-blue-dark;

/* Main content wrapper */
@color-bg-main-wrapper: @color-white;

/* Ant Table - Main Grid */
@color-bg-table-header: @color-blue-light;
@color-bg-table-header-sorter-icon: #9bb7c0;
@color-bg-table-header-input-border: #99ccdc;
@color-bg-table-header-hover: @color-blue-light;
@color-bg-table-row-dark: #f6fdff;
@color-bg-table-row-light: @color-white;
@color-table-header-top-bg: #b8dce8;
@color-table-header-bottom-bg: @color-blue-light;
@color-table-row-highlight: #f0f5f9;
@color-table-row-hover: #e2ecf3;

/* File area action panel */
@color-bg-filearea-actionpanel: @color-gray-dark;
@color-shadow-filearea-actionpanel: @color-gray-dark;
@color-font-filearea-action-list: @color-white;
@color-filearea-action-list-icon: @color-white;
@color-border-filter-dropdown-btn: @color-white;

/* Wizard Stepper */
@color-bg-wizard-wrapper: @color-white;
@color-font-verification-icon: @color-white;
@color-bg-wizard-icon-active: #C8102E;
@color-border-wizard-icon-active: #C8102E;
@color-bg-wizard-icon-previous: #C8102E;
@color-border-wizard-icon-previous: #C8102E;
@color-bg-wizard-icon-wait: @color-white;
@color-border-wizard-icon-wait: @color-gray-medium;
@color-bg-wizard-container: @color-white;
@color-bg-wizard-button-section: @color-white;

/* Collapse - Accordian (Common / Primary) */
@color-bg-accordian-header: @color-gray-dark;
@color-font-accordian-header: @color-white;
@color-bg-accordian-header-arrow: @color-white;

/* Collapse - Sub Accordian (Secondary) */
@color-bg-sub-accordian-wrapper: @color-white;
@color-bg-sub-accordian-header: @color-white;
@color-font-sub-accordian-header: @color-blue-dark;
@color-font-sub-accordian-header-icon: @color-blue-dark;

/* Tab Navigation (Common / Primary) */
@color-bg-common-tab-inactive: @color-white;
@color-font-common-tab-inactive: @color-black;
@color-border-common-tab-inactive: @color-gray-light;
@color-bg-common-tab-active: @color-white;
@color-font-common-tab-active: @color-blue-dark;

/* Tab Navigation (Secondary) */
@color-bg-secondary-tab-active: @color-green;
@color-font-secondary-tab-active: @color-white;
@color-border-secondary-tab-inactive: @color-green;
@color-bg-secondary-tab-inactive: @color-gray-light;
@color-font-secondary-tab-inactive: @color-black;

/* Common Grid */
@color-bg-grid-filterby-tag: @color-green;
@color-border-grid-filterby-tag: @color-green;
@color-font-grid-filterby-tag: @color-white;
@color-font-grid-filterby-tag-icon: @color-white;
@color-font-grid-recordcount: @color-black;

/* History Grid */
@color-bg-history-grid-header: @color-white;
@color-font-history-grid-header: @color-blue-dark;
@color-font-history-grid-record: @color-black;

/* Secondary Grid */
@color-bg-secondary-header: @color-white;
@color-font-secondary-header: @color-blue-dark;
@color-bg-remove-record-icon: @color-red;
@color-font-remove-record-icon: @color-white;

/* Common Modal */
@color-bg-modal-header: @color-gray-dark;
@color-font-modal-header: @color-white;
@color-bg-modal: @color-white;
@color-close-icon-modal: @color-white;

/* Pagination */
@color-bg-pagination-active: @color-gray-dark;
@color-border-pagination-active: @color-gray-dark;
@color-font-pagination-active: @color-white;

/* File finder section - file area */
@color-bg-file-finder-header: @color-gray-dark;
@color-font-file-finder-header: @color-white;
@color-bg-save-new-filter-btn: @color-green;
@color-border-save-new-filter-btn: @color-green;
@color-font-save-new-filter-btn: @color-white;
@color-filter-area-shadow: #4d7e8d;
@color-bg-filter-area: @color-gray-dark;
@color-bg-progress-bar: @color-green;

/* Portal file area */
@color-bg-portal-action-button-wrapper: @color-blue-dark;
@color-bg-portal-action-button: @color-white;
@color-font-portal-action-button: @color-blue-dark;

/* FF Management - edit , License Management - edit */
@color-bg-functional-flow-management-wrapper-edit: @color-white;
@color-bg-license-management-wrapper-edit: @color-white;
@color-bg-organization-management-wrapper-edit: @color-white;

/* Content Wrapper */
@color-bg-content-wrapper: @color-white;

/* Drag and Drop Container */
@color-bg-drag-drop-container: @color-blue-dark;
@color-border-drag-drop-container: @color-blue-dark;
@color-font-drag-drop-container: @color-black;
@color-bg-browse-btn: @color-blue-dark;
@color-font-browse-btn: @color-white;

/* Upload Progress bar */
@color-bg-upload-progress-wrapper: @color-white;

/* Ant Drawer */
@color-bg-drawer-header: @color-gray-dark;
@color-font-drawer-header-title: @color-white;
@color-font-drawer-header-icon: @color-white;
@color-bg-drawer-body: @color-white;

/* External Window */
@color-bg-external-wrapper: @color-gray-light;
@color-bg-external-wrapper-header: @color-gray-light;
@color-bg-external-title-section: @color-white;
@color-font-external-title-section: @color-blue-dark;
@color-bg-external-content: @color-white;

/* Checkbox styles */
@color-bg-checkbox-default: @color-white;
@color-border-checkbox-default: @color-gray-dark;
@color-font-checkbox-default: @color-gray-dark;

/* Global Variables */
@color-primary: @color-gray-dark;
@color-secondary: @color-blue-dark;
@color-accent-secondary: @color-green;
@color-accent: #00bcd4;
@color-bg: @color-gray-light;
@color-bg-secondary: #e4e7e9;
@color-tag: #E2ECF3;
@color-font-white: @color-white;
@color-modal-bg-sub-header: linear-gradient(180deg, @color-blue-light 0%, @color-blue-light 100%);
@color-border: #e7e9ec;
@color-accent-border: #666;
@color-danger: @color-red;
@color-success: #207e31;
@color-warning: #b35900;
@border-color-base: #f6f6f6;
@text-color: fade(black, 65%);
@yj-placeholder-txt: #bfbfbf;

@color-info-alert: #004085;
@color-info-alert-border: #b8daff;
@color-info-alert-bg: #b8daff;

@color-success-alert: #155724;
@color-success-alert-border: #c3e6cb;
@color-success-alert-bg: #d4edda;

@color-warning-alert: #856404;
@color-warning-alert-border: @color-yellow;
@color-warning-alert-bg: #fff3cd;

@color-error-alert: #721c24;
@color-error-alert-border: #f5c6cb;
@color-error-alert-bg: #f8d7da;

@color-disabled-bg: #bcbcbc;
@color-disabled-border: #aeaeae;

/* Media queries breakpoint */
@breakpoint-tablet: ~'only screen and (max-width: 1366px)';
